import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  allowanceBUSD,
  setBUSD_NFTStakeApprove,
} from "../../../ContractAction/BUSDContractAction";
import {
  setRepurchaseNFT,
  getCheckRepurchase,
} from "../../../ContractAction/EVCNFTStakeContractAction";
import { Box } from "@chakra-ui/react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { getWalletOfOwner, setRepurchaseNFTTrendAvatar } from "../../../ContractAction/EVCNFTContractAction";
import {
  getTokensOfStaker,
  setClaimReward,
  getUnClaimableReward,
  getCurrentAPROfNFTLevel,
  getNextClaimingTime,
  getRewardPercentage,
} from "../../../ContractAction/EVCNFTStakeContractAction";
import { setWithdrawNFT } from "../../../ContractAction/EVCNFTStakeContractAction";
import {
  ContractAddressTTAVATARSSTAKE,
  ContractAddressTTAVATARS,
  VIEWERADPORTAL,
  BaseExplorerUrl,
  IPFSTrend, JAVAADDSCOUNT
} from "../../../ContractAction/ContractDependency";
import { getIsApprovedForAll } from "../../../ContractAction/EVCNFTContractAction";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import level1 from "../Mint/Images/level1.png";
import level2 from "../Mint/Images/level2.png";
import level3 from "../Mint/Images/level3.png";
import level4 from "../Mint/Images/level4.png";
import level5 from "../Mint/Images/level5.png";
import level6 from "../Mint/Images/level6.png";
import level7 from "../Mint/Images/level7.png";
import level8 from "../Mint/Images/level8.png";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,

} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery } from "@tanstack/react-query";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "yellow" : "#308fe8",
  },
}));

//dev: Progressbar for  EVC NFT
const Progressbar = ({ bgcolor, progress, height }) => {
  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: "whitesmoke",
    borderRadius: 40,
    margin: 0,
  };

  const Childdiv = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "left",
  };

  const progresstext = {
    paddingLeft: "25px",
    color: "black",
    fontWeight: "bold",
    textAlign: "center",
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}>{`${progress}%`}</span>
      </div>
    </div>
  );
};

//dev: Start Mint Page
function UnstakeComp({ updated, setUpdated }) {
  //dev: Stake NFTs

  const navigate = useNavigate();
  const [walletOwner, SetWalletOwner] = useState();
  const [rewardObj, setRewardObj] = useState({});
  const [rewardPercentageObj, setRewardPercentageObj] = useState({});
  const [claimTimeObj, setClaimTimeObj] = useState({});
  const [checkRepurchaseForId, setCheckRepurchaseForId] = useState({});
  const [BusdAllowanceNftAvatars, setBusdAllowanceNftAvatars] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:991px)");

  // const [updated, setUpdated] = useState();
  const [adsStatus, setAdsStatus] = useState(
    JSON.parse(localStorage.getItem("adsStatus")) || "false"
  );

  const { t } = useTranslation();
  //dev: Unstake NFTs
  const [stakeOfOwnerNFTIDs, setStakeOfOwnerNFTIDs] = useState([]);
  //dev: Img URL
  const imgUrl = IPFSTrend;
  const [isApproved, setApproved] = useState();
  const [apy, setApy] = useState({});

  const redirectToBuyAvatars = () => {
    navigate("/dashboard/tt-avatars");
  };

  useEffect(() => {
    //dev: Get the  NFT Staking Data
    const getNFTStakingData = async () => {
      const WalletOfOwner = await getWalletOfOwner();
      const StakeOfOwnerNFTIDs = await getTokensOfStaker();
      const approved = await getIsApprovedForAll();
      const allowanceBusdToNftAvatars = await allowanceBUSD(
        ContractAddressTTAVATARS
      );
      SetWalletOwner(WalletOfOwner);
      setStakeOfOwnerNFTIDs(StakeOfOwnerNFTIDs);
      setApproved(approved);
      setBusdAllowanceNftAvatars(allowanceBusdToNftAvatars);
    };
    getNFTStakingData();
  }, [updated]);

  useEffect(() => {
    //dev: get the Reward
    const getReward = async () => {
      if (typeof stakeOfOwnerNFTIDs !== "undefined") {
        for (let i = 0; i < stakeOfOwnerNFTIDs.length; i++) {
          const rewardinfo = await getUnClaimableReward(stakeOfOwnerNFTIDs[i]);
          const rewardPercentage = await getRewardPercentage(
            stakeOfOwnerNFTIDs[i]
          );
          const timeInfo = await getNextClaimingTime(stakeOfOwnerNFTIDs[i]);
          const checkRepurchaseForIdBool = await getCheckRepurchase(
            stakeOfOwnerNFTIDs[i]
          );
          const apyInfo = await getCurrentAPROfNFTLevel(stakeOfOwnerNFTIDs[i]);
          setRewardObj((prevRewardObj) => ({
            ...prevRewardObj,
            [stakeOfOwnerNFTIDs[i]]: Number(rewardinfo).toLocaleString(
              undefined,
              { maximumFractionDigits: 10 }
            ),
          }));
          setRewardPercentageObj((prevRewardPercentageObj) => ({
            ...prevRewardPercentageObj,
            [stakeOfOwnerNFTIDs[i]]: rewardPercentage,
          }));
          setClaimTimeObj((prevClaimTimeObj) => ({
            ...prevClaimTimeObj,
            [stakeOfOwnerNFTIDs[i]]: timeInfo,
          }));
          setCheckRepurchaseForId((prevCheckRepurchaseForIdBool) => ({
            ...prevCheckRepurchaseForIdBool,
            [stakeOfOwnerNFTIDs[i]]: checkRepurchaseForIdBool,
          }));
          setApy((prevNFTLevelAPR) => ({
            ...prevNFTLevelAPR,
            [stakeOfOwnerNFTIDs[i]]: apyInfo,
          }));
        }
      }
    };
    getReward();
    const updateLocalValueGetReward = setInterval(getReward, 10000);
    return () => clearInterval(updateLocalValueGetReward);
  }, [stakeOfOwnerNFTIDs]);

  const ClaimRewards = async (data2) => {
    if (claimTimeObj[data2] <= epoch) {
      const response = await setClaimReward(data2);
      if(response?.status === 1){
        fetchAddtrue();
      }
      if (response?.transactionHash) {
        handleTxhashShow(response?.transactionHash);
      } else if (response === undefined) {
        toast.error("Transaction Cancelled");
      }

    } else {
      alert("wait till next claimable timing");
    }
  };

  useEffect(() => {
    //set the walletOwner on Local Storage
    window.localStorage.setItem("walletOwner", walletOwner);
  }, []);

  //dev: Define  Next Claim Time
  const nextClaimTime = window.localStorage.getItem("nextClaim ");

  //dev: Show the Current Time
  const start = Date.now();
  let epoch = start / 1000;
  let myepoch = epoch.toFixed(0);

  const [stake, setStake] = useState(false);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));



  const getImgUrlId = (data2) => {

    if (data2 >= 1 && data2 <= 600000) {
      return 1;
    } else if (data2 >= 600001 && data2 <= 900000) {
      return 21;
    } else if (data2 >= 900001 && data2 <= 1100000) {
      return 31;
    } else if (data2 >= 1100001 && data2 <= 1200000) {
      return 41;
    } else if (data2 >= 1200001 && data2 <= 1250000) {
      return 51;
    } else if (data2 >= 1250001 && data2 <= 1270000) {
      return 61;
    } else if (data2 >= 1270001 && data2 <= 1280000) {
      return 71;
    } else if (data2 >= 1280001 && data2 <= 1285000) {
      return 81;
    } else {
      return null;
    }
  };

  const getImgAsset = (data1) => {
    if (data1 >= 1 && data1 <= 600000) {
      return level1;
    } else if (data1 >= 600001 && data1 <= 900000) {
      return level2;
    } else if (data1 >= 900001 && data1 <= 1100000) {
      return level3;
    } else if (data1 >= 1100001 && data1 <= 1200000) {
      return level4;
    } else if (data1 >= 1200001 && data1 <= 1250000) {
      return level5;
    } else if (data1 >= 1250001 && data1 <= 1270000) {
      return level6;
    } else if (data1 >= 1270001 && data1 <= 1280000) {
      return level7;
    } else if (data1 >= 1280001 && data1 <= 1285000) {
      return level8;
    } else {
      return null;
    }
  };

  const fetch21Ads = async () => {
    try {
      const walletAddress = localStorage.getItem("connectedAccount");
      const response = await axios.get(
        `${JAVAADDSCOUNT}${walletAddress}`
      );

      if (response?.data?.statusCode === 200) {
        localStorage.setItem(
          "adsStatus",
          JSON.stringify(response?.data?.watchedAdsStatus)
        );
        setAdsStatus(localStorage.getItem("adsStatus"));
        return response.data;
      } else if (response?.data?.errorCode == 404) {
        setAdsStatus(false);
        toast.error(response?.data?.developermsg);
      }
    } catch (error) {
      if (error?.response?.data?.errorCode === 404) {
        setAdsStatus(false);
      }
    }
  };


  const { data: adsData } = useQuery({ queryKey: ['adsData'], queryFn: fetch21Ads })
  console.log(adsData, 'adsDataadsData');
  const handleClickToast = () => {

    alert("To UnStake Watch Ad's first");
  };

  const fetchAddtrue = async () => {
    const note = true;

    try {
      const walletAddress = localStorage.getItem("connectedAccount");
      const response = await axios.get(
        `${JAVAADDSCOUNT}${walletAddress}?note=${note}`
      );
      if (response?.data?.statusCode === 200) {
        localStorage.setItem(
          "adsStatus",
          JSON.stringify(response?.data?.watchedAdsStatus)
        );
        setAdsStatus(localStorage.getItem("adsStatus"));
        fetch21Ads();
      }
      if (response?.data?.errorCode === 404) {
        setAdsStatus(false);
        toast.success(response?.data?.developermsg);
        localStorage.setItem(
          "adsStatus",
          JSON.stringify(response?.data?.watchedAdsStatus)
        );
      }
    } catch (error) {
      console.log("Error fetching ads:", error);
    }
  };

  const handleUnStake = async (data2) => {
    try {
      setIsLoading(true);
      const txHash = await setWithdrawNFT(data2);
      setUpdated(txHash);
      if (txHash) {
        handleTxhashShow(txHash);
        setIsLoading(false);
      } else if (txHash === undefined) {
        toast.error("Transaction Cancelled");
      }
      setIsLoading(false);

    } catch (e) {
      setIsLoading(false);

    }
  };
  const handleTxhashShow = async (e) => {
    toast.success(
      <div>
        Transaction Receipt: <br />
        <a
          href={`${BaseExplorerUrl}tx/${e}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Block Explorer
        </a>
      </div>,
      {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="mt-4" style={{ padding: "0px" }}>
      <div
        className="row"
      >
        {
          stakeOfOwnerNFTIDs?.map((data2, index) => (
            <div className="col-12 col-md-6 col-xl-4 mb-4" key={index}>
              <div className="card-evc h-100 d-flex flex-column">
                <div className="evc-avatar mb-2">
                  <img
                    src={getImgAsset(data2)}
                    alt=""
                    className="img-fluid"
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "10px",
                      marginBottom: "5%",
                    }}
                  />
                </div>
                <span className="evc-price-title">
                  {t("APR")} : <span>{apy[data2]}%</span>
                </span>
                <div className="d-flex ">
                  <span className="evc-price-title">{t("TRND Earned")}: </span>
                  <span className="evc-price-title">{rewardObj[data2]} </span>
                </div>
                <div className="p-2 px-1">
                  <div className="evc-title mb-2">
                    {" "}
                    {data2 >= 1 && data2 <= 600000
                      ? t("Crypto Newbies")
                      : data2 >= 600001 && data2 <= 900000
                        ? t("Crypto Enthusiast")
                        : data2 >= 900001 && data2 <= 1100000
                          ? t("Crypto Entrepreneur")
                          : data2 >= 1100001 && data2 <= 1200000
                            ? t("Crypto Investor")
                            : data2 >= 1200001 && data2 <= 1250000
                              ? t("Crypto King")
                              : data2 >= 1250001 && data2 <= 1270000
                                ? t("Blockchain Mogul")
                                : data2 >= 1270001 && data2 <= 1280000
                                  ? t("Bitcoin Billionaire")
                                  : data2 >= 1280001 && data2 <= 1285000
                                    ? t("CryptoCap Tycoon")
                                    : null}

                  </div>
                  <Box className="mb-2" sx={{ flexGrow: 1 }}>
                    <Progressbar
                      bgcolor="#64dd17"
                      progress={rewardPercentageObj[data2]}
                      height={isMobile ? 20 : 20}
                    />
                  </Box>
                  <br></br>

                  {checkRepurchaseForId[data2] ? (
                    (data2 >= 1 && data2 <= 600000
                      ? 100
                      : data2 >= 600001 && data2 <= 900000
                        ? 500
                        : data2 >= 900001 && data2 <= 1100000
                          ? 1000
                          : data2 >= 1100001 && data2 <= 1200000
                            ? 2500
                            : data2 >= 1200001 && data2 <= 1250000
                              ? 5000
                              : data2 >= 1250001 && data2 <= 1270000
                                ? 10000
                                : data2 >= 1270001 && data2 <= 1280000
                                  ? 25000
                                  : data2 >= 1280001 && data2 <= 1285000
                                    ? 50000
                                    : 0) <= Number(BusdAllowanceNftAvatars) ? (
                      <div style={{ marginTop: "-15" }}>
                        <div className="d-grid gap-2 border-g">
                          <button
                            className="btn btn-dark-bg-custom evc-title"
                            onClick={() => setRepurchaseNFTTrendAvatar(data2)}
                          >
                            {t("Repurchase")}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div style={{ marginTop: "-15" }}>
                        <div className="d-grid gap-2 border-g">
                          <button
                            className="btn btn-dark-bg-custom evc-title"
                            onClick={() => setBUSD_NFTStakeApprove()}
                          >
                            {t("Approve Repurchase")}
                          </button>
                        </div>
                      </div>
                    )
                  ) : (
                    <div
                      className="d-flex gap-2 justify-content-center "
                      style={{ marginTop: "-15px" }}
                    >
                      <div className="d-grid gap-2 border-g">
                        <button
                          className="btn btn-dark-bg-custom evc-title"
                          type="button"
                          onClick={() => {
                            const adsStatus = localStorage.getItem("adsStatus");
                            if (adsStatus === "true") {
                              ClaimRewards(data2);
                            } else {
                              alert("Please watch ads to claim rewards");
                            }
                          }}
                        //onClick={() => handleClickOpen()}
                        >
                          {t("Claim")}
                        </button>
                      </div>
                      <div className="d-grid gap-2 border-g">
                        <button
                          className="btn btn-dark-bg-custom evc-title "
                          type="button"
                          // onClick={() => {
                          //   if (localStorage.getItem("adsStatus") === "true") {
                          //     handleUnStake(data2).then(() => fetchAddtrue());
                          //   } else {
                          //     handleClickToast();
                          //   }
                          // }}
                          onClick={() => handleUnStake(data2).then(() => fetchAddtrue())}
                          disabled={isLoading}
                        >
                          {t("Unstake")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        }
        <div className="d-flex flex-row justify-content-around mt-3 mb-5 w-100">
          <div className="d-lg-flex flex-row justify-content-center gap-4 w-100">
            <div className="btn-g mb-2 mb-lg-0"><button className="btn btn-bottom">  {adsData?.watchedAds}{" "}Ads Watched</button></div>
            <div className="btn-g mb-2 mb-lg-0"><button className="btn btn-bottom">{adsData?.remainedAds}{" "}Ads Remaining</button></div>
            <div className="btn-g"><button className="btn btn-bottom" disabled={localStorage.getItem("adsStatus") === "true"} onClick={() => window.open("https://viewer.trendads.ai/", "_blank")}>Watch Ad</button></div>
          </div>
        </div>
      </div>

      <div>
        <Dialog open={open} onClose={handleClose} style={{ borderRadius: "0px important" }}>
          <DialogContent style={{ backgroundColor: "rgb(32, 31, 36)", borderRadius: "0px !important" }}>
            <DialogContentText
              className="text-center"
              style={{ textDecoration: "underline", fontWeight: "bold", color: "white" }}
            >
              The Claim and UnStake button will become active in a few days following the
              maintenance update.
            </DialogContentText>
          </DialogContent>
          <DialogActions className="d-flex justify-content-center" style={{ backgroundColor: "rgb(32, 31, 36)" }}>
            <Button onClick={handleClose} style={{ backgroundColor: "rgba(78, 134, 100, 1)", color: "black", fontWeight: "bold", width: "100%" }}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default UnstakeComp;
