import Web3 from "web3";
import { abiEVCTokenStake } from "./ABI/EVCStake"
import { ABIPancakeRouter } from "./ABI/PancakeRouter"
import { abiBUSD } from "./ABI/BUSD";
import { APISWAPTRADEHISTORY, ContractAddressTRNDToken, ContractAddressUSDC, ContractAddressTrendRouter, ContractAddressPairTRNDUSDC, CurrentChainID, AUTHUSERNAME, AUTHUSERPASSWORD, getCurrentSigner, getCurrentAccount } from "./ContractDependency.js";
import { postNotification } from "../ContractAction/EVCNFTContractAction";
import { ethers } from 'ethers';
import { ethersProvider } from "../config/context.js";

const abiEVCToken = abiEVCTokenStake
const abiEVCRouter = ABIPancakeRouter;

//Sayyed Code
// export const allowanceBusdtoEvcRouter = async () => {
//     console.log("allowanceBusdtoEvcRouter")
//     try {
//         if (window.ethereum) {
//             const web3 = new Web3(window.ethereum);
//             const accounts = await window.ethereum.request({
//                 method: "eth_accounts",
//             });
//             const account = accounts[0];
//             const contracts = new web3.eth.Contract(abiBUSD, ContractAddressUSDC);
//             console.log("account", account);
//             console.log("ContractAddressTrendRouter", ContractAddressTrendRouter);
//             const response = await contracts.methods.allowance(account, ContractAddressTrendRouter).call();
//             const allowance = web3.utils.fromWei(response, 'ether')
//             console.log("allowanceBusdtoEvcRouter", allowance);
//             return allowance;
//         }
//     } catch (error) {
//         console.log("allowanceBusdtoEvcRouter_error", error);
//     }
// };
//Shreyash Code
export const allowanceBusdtoEvcRouter = async () => {
    console.log("allowanceBusdtoEvcRouter");
    if (!ethersProvider) {
      console.error("ethersProvider is not available");
      return;
    }
    const signer = await getCurrentSigner();
    const account = await getCurrentAccount();
    const contracts = new ethers.Contract(ContractAddressUSDC, abiBUSD, signer);
    console.log("account", account);
    console.log("ContractAddressTrendRouter", ContractAddressTrendRouter);
    try {
      const response = await contracts.allowance(account, ContractAddressTrendRouter);
      const allowance = ethers.utils.formatEther(response);
      console.log("allowanceBusdtoEvcRouter", allowance);
      return allowance;
    } catch (error) {
      console.error("allowanceBusdtoEvcRouter_error", error);
      return null; // Or handle the error as needed
    }
  };
  
allowanceBusdtoEvcRouter()

//Sayyed Code
// export const allowanceEvcTokentoEvcRouter = async () => {
//     console.log("allowanceEvcTokentoEvcRouter")
//     try {
//         if (window.ethereum) {
//             const web3 = new Web3(window.ethereum);
//             const accounts = await window.ethereum.request({
//                 method: "eth_accounts",
//             });
//             const account = accounts[0];
//             const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//             console.log("account", account);
//             console.log("ContractAddressTrendRouter", ContractAddressTrendRouter);
//             const response = await contracts.methods.allowance(account, ContractAddressTrendRouter).call();
//             const allowance = web3.utils.fromWei(response, 'ether')
//             console.log("allowanceEvcTokentoEvcRouter", allowance);
//             return allowance;
//         }
//     } catch (error) {
//         console.log("allowance", error);
//     }
// };
//Shreyash Code
export const allowanceEvcTokentoEvcRouter = async () => {
    console.log("allowanceEvcTokentoEvcRouter");
    if (!ethersProvider) {
      console.error("ethersProvider is not available");
      return;
    }
    const signer = await getCurrentSigner();
    const account = await getCurrentAccount();
    const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, signer);
    console.log("account", account);
    console.log("ContractAddressTrendRouter", ContractAddressTrendRouter);
    try {
      const response = await contracts.allowance(account, ContractAddressTrendRouter);
      const allowance = ethers.utils.formatEther(response);
      console.log("allowanceEvcTokentoEvcRouter", allowance);
      return allowance;
    } catch (error) {
      console.error("allowanceEvcTokentoEvcRouter_error", error);
      return null; // Or handle the error as needed
    }
  };
  

//Sayyed code
// export const setBUSDApproveRouter = async (amountBusd) => {
//     try {
//         if (window.ethereum) {
//             const web3 = new Web3(window.ethereum);
//             await window.ethereum.request({ method: "eth_requestAccounts" });
//             // Get the selected account
//             const accounts = await window.ethereum.request({ method: "eth_accounts" });
//             console.log("accounts", accounts)
//             const account = accounts[0];
//             const currentChainId = await web3.eth.net.getId();
//             if (currentChainId !== CurrentChainID) {
//                 await web3.currentProvider.request({
//                     method: "wallet_switchEthereumChain",
//                     params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//                 });
//             }
//             const contracts = new web3.eth.Contract(abiBUSD, ContractAddressUSDC);
//             const amountBusdInWei = web3.utils.toWei(amountBusd.toString(), 'ether');
//             console.log({ amountBusdInWei })
//             const estimatedGasLimit = await contracts.methods
//                 .approve(ContractAddressTrendRouter, amountBusdInWei)
//                 .estimateGas({ from: account });
//             const gasPrice = await web3.eth.getGasPrice();
//             const approveRouter = await contracts.methods.approve(ContractAddressTrendRouter, amountBusdInWei).send({
//                 from: account,
//                 gas: estimatedGasLimit,
//                 gasPrice: gasPrice
//             });
//             if (approveRouter) {
//                 return true;
//             }
//         };
//     } catch (error) {
//         console.log("approveRouter", error);
//     }
// };

//Sheyash code
export const setBUSDApproveRouter = async (amountBusd) => {
    try {
        const signer = await getCurrentSigner();
        const contracts = new ethers.Contract(ContractAddressUSDC, abiBUSD, signer);
        const amountBusdInWei = ethers.utils.parseUnits(amountBusd.toString(), 18);  // Assuming BUSD has 18 decimals
        console.log({ amountBusdInWei });
        const estimatedGasLimit = await contracts.estimateGas.approve(ContractAddressTrendRouter, amountBusdInWei);
        const gasPrice = await ethersProvider.getGasPrice();
        const approveRouter = await contracts.approve(ContractAddressTrendRouter, amountBusdInWei, {
            gasLimit: estimatedGasLimit,
            gasPrice: gasPrice
        });
        const receipt = await approveRouter.wait();
        if (approveRouter) {
            return true;
        }

    } catch (error) {
        console.log("approveRouter error:", error);
        return false;
    }
};

//Sayyed code
// export const setEVCTokenApproveEvcRouter = async (amount) => {
//     console.log("setEVCTokenApproveEvcRouter");
//     try {
//         if (window.ethereum) {
//             const web3 = new Web3(window.ethereum);
//             await window.ethereum.request({ method: "eth_requestAccounts" });
//             // Get the selected account
//             const accounts = await window.ethereum.request({
//                 method: "eth_accounts",
//             });
//             const account = accounts[0];
//             const currentChainId = await web3.eth.net.getId();
//             if (currentChainId !== CurrentChainID) {
//                 await web3.currentProvider.request({
//                     method: "wallet_switchEthereumChain",
//                     params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//                 });
//             }
//             const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//             const amountEvcInWei = web3.utils.toWei(amount.toString(), 'ether');
//             const estimatedGasLimit = await contracts.methods
//                 .approve(ContractAddressTrendRouter, amountEvcInWei)
//                 .estimateGas({ from: account });
//             const gasPrice = await web3.eth.getGasPrice();
//             const approveEvcRouter = await contracts.methods
//                 .approve(ContractAddressTrendRouter, amountEvcInWei)
//                 .send({
//                     from: account,
//                     gas: estimatedGasLimit,
//                     gasPrice: gasPrice
//                 });
//             console.log("setEVCTokenApproveEvcRouter", approveEvcRouter);
//         }
//     } catch (error) {
//         console.log("approveEvcRouterError", error);
//     }
// };

//Shreyash code 
export const setEVCTokenApproveEvcRouter = async (amount) => {
    console.log("setEVCTokenApproveEvcRouter");
    try {
        const signer = await getCurrentSigner();
        const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, signer);
        const amountEvcInWei = ethers.utils.parseUnits(amount.toString(), 18);  // Assuming EVC Token has 18 decimals
        const estimatedGasLimit = await contracts.estimateGas.approve(ContractAddressTrendRouter, amountEvcInWei);
        const gasPrice = await ethersProvider.getGasPrice();
        const approveEvcRouter = await contracts.approve(ContractAddressTrendRouter, amountEvcInWei, {
            gasLimit: estimatedGasLimit,
            gasPrice: gasPrice
        });
        const EVCTokenApproveEvcRouterReceipt = await approveEvcRouter.wait();
        console.log("setEVCTokenApproveEvcRouter", EVCTokenApproveEvcRouterReceipt?.transactionHash);
        return EVCTokenApproveEvcRouterReceipt?.transactionHash;
    } catch (error) {
        console.log("approveEvcRouterError", error);
    }
};

//Sayyed code
// export const setSwapStableForTokens = async (amountIn) => {
//     try {
//         if (window.ethereum) {
//             const web3 = new Web3(window.ethereum);
//             // Ensure the user is connected to their wallet
//             await window.ethereum.request({ method: 'eth_requestAccounts' });
//             // Get the selected account
//             const accounts = await window.ethereum.request({
//                 method: 'eth_accounts',
//             });
//             const account = accounts[0];
//             // Create a new instance of the contract
//             const contract = new web3.eth.Contract(abiEVCRouter, ContractAddressTrendRouter);
//             // Convert the input values to BigNumber or use string (if required)
//             const amountInWei = web3.utils.toWei(amountIn.toString(), 'ether');
//             console.log("amountInWei", amountInWei)
//             const amountOutMin = 1;
//             const amountOutMinWei = web3.utils.toWei(amountOutMin.toString(), 'ether');
//             console.log("amountOutMinWei", amountOutMinWei)
//             // Calculate the deadline as 10 minutes ahead of the current time
//             const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
//             const deadline = currentTimestamp + 600; // 600 seconds = 10 minutes
//             // Execute the swapExactTokensForTokens2 function
//             const estimatedGasLimit = await contract.methods
//                 .swapExactTokensForTokens2(amountInWei, amountOutMinWei, account, account, deadline)
//                 .estimateGas({ from: account });
//             const gasPrice = await web3.eth.getGasPrice();
//             const result = await contract.methods
//                 .swapExactTokensForTokens2(amountInWei, amountOutMinWei, account, account, deadline)
//                 .send({
//                     from: account,
//                     gas: estimatedGasLimit,
//                     gasPrice: gasPrice
//                 });
//             console.log('setSwapStableForTokens_result:', result);
//         }
//     } catch (error) {
//         console.error('setSwapStableForTokens_error:', error);
//     }
// };

//Shreyash code 
export const setSwapStableForTokens = async (amountIn) => {
    try {
        const signer = await getCurrentSigner();
        const account = await getCurrentAccount();
        const contract = new ethers.Contract(ContractAddressTrendRouter, abiEVCRouter, signer);
        const amountInWei = ethers.utils.parseUnits(amountIn.toString(), 18);
        const amountOutMinWei = ethers.utils.parseUnits('1', 18);
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const deadline = currentTimestamp + 600;
        const estimatedGasLimit = await contract.estimateGas.swapExactTokensForTokens2(
            amountInWei, amountOutMinWei, account, account, deadline
        );
        const gasPrice = await ethersProvider.getGasPrice();
        const result = await contract.swapExactTokensForTokens2(
            amountInWei, amountOutMinWei, account, account, deadline,
            { gasLimit: estimatedGasLimit, gasPrice: gasPrice }
        );
        console.log('setSwapStableForTokens_result:', result);
    } catch (error) {
        console.error('setSwapStableForTokens_error:', error);
    }
};

//Sayyed code
// export const setSwapTokensForStable = async (amountIn) => {
//     try {
//         if (!window.ethereum) return;
//         const web3 = new Web3(window.ethereum);
//         await window.ethereum.request({
//             method: 'eth_requestAccounts'
//         });
//         const [account] = await window.ethereum.request({
//             method: 'eth_accounts'
//         });
//         const contract = new web3.eth.Contract(abiEVCRouter, ContractAddressTrendRouter);
//         const amountInWei = web3.utils.toWei(amountIn.toString(), 'ether');
//         const amountOutMin = 0;
//         const path = [ContractAddressTRNDToken, ContractAddressUSDC];
//         const currentTimestamp = Math.floor(Date.now() / 1000);
//         const deadline = currentTimestamp + 600;
//         const estimatedGasLimit = await contract.methods
//             .swapExactTokensForTokensSupportingFeeOnTransferTokens(amountInWei, amountOutMin, path, account, deadline)
//             .estimateGas({
//                 from: account
//             });
//         const gasPrice = await web3.eth.getGasPrice();
//         const result = await contract.methods
//             .swapExactTokensForTokensSupportingFeeOnTransferTokens(amountInWei, amountOutMin, path, account, deadline)
//             .send({
//                 from: account,
//                 gas: estimatedGasLimit,
//                 gasPrice
//             });
//         console.log('swapExactTokensForTokens_E2B_result:', result);
//         const amountOut = await getValueOutEvcToBusd(amountIn);
//         console.log("swapExactTokensForTokensamountOut", amountOut);
//         const requestBody = {
//             maker: account,
//             type: 'Sell',
//             from: amountIn,
//             to: amountOut,
//             price: amountOut,
//         };
//         const apiResponse = await fetch(APISWAPTRADEHISTORY, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Basic ' + btoa(`${AUTHUSERNAME}:${AUTHUSERPASSWORD}`)

//             },

//             body: JSON.stringify(requestBody),
//         });
//         const responseData = await apiResponse.json();
//         console.log('SwapTradeHistoryApiData:', responseData);
//         const notificationMessage = `swapped ${amountIn} TRND for: ${amountOut} USDC`;
//         await postNotification(account, notificationMessage);
//         console.log('swapExactTokensForTokens_E2B_resultHash:', result.transactionHash);
//         return result.transactionHash;
//     } catch (error) {
//         console.error('swapExactTokensForTokens_E2B_error:', error);
//     }
// };
//Shreyash code 
export const setSwapTokensForStable = async (amountIn) => {
  try {
      const signer = await getCurrentSigner();
      const account = await signer.getAddress();
      console.log("accountSwap",account);
      const contract = new ethers.Contract(ContractAddressTrendRouter, abiEVCRouter, signer);
      const amountInWei = ethers.utils.parseUnits(amountIn.toString(), 18);
      const amountOutMin = ethers.utils.parseUnits('0', 18);
      const path = [ContractAddressTRNDToken, ContractAddressUSDC];
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const deadline = currentTimestamp + 600;
      const estimatedGasLimit = await contract.estimateGas.swapExactTokensForTokensSupportingFeeOnTransferTokens(
          amountInWei, amountOutMin, path, account, deadline
      );
      const gasPrice = await ethersProvider.getGasPrice();
      const result = await contract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
          amountInWei, amountOutMin, path, account, deadline,
          { gasLimit: estimatedGasLimit, gasPrice: gasPrice }
      );
      const swapTokensForStableReceipt = await result.wait();
      console.log('swapExactTokensForTokens_E2B_result:', swapTokensForStableReceipt);
      const amountOut = await getValueOutEvcToBusd(amountIn);
      console.log("swapExactTokensForTokensamountOut", amountOut);
      const requestBody = {
          maker: account,
          type: 'Sell',
          from: amountIn,
          to: amountOut,
          price: amountOut,
      };
      const apiResponse = await fetch(APISWAPTRADEHISTORY, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + btoa(`${AUTHUSERNAME}:${AUTHUSERPASSWORD}`)
          },
          body: JSON.stringify(requestBody),
      });
      const responseData = await apiResponse.json();
      console.log('SwapTradeHistoryApiData:', responseData);
      const notificationMessage = `Swapped ${amountIn} TRND for: ${amountOut} USDC`;
      swapTokensForStableReceipt.status == 1 ? await postNotification(account, notificationMessage) : console.error('swapExactTokensForTokens_E2B_resultHash:', swapTokensForStableReceipt?.transactionHash);
      console.log('swapExactTokensForTokens_E2B_resultHash:', swapTokensForStableReceipt?.transactionHash);
      return swapTokensForStableReceipt?.transactionHash;
  } catch (error) {
      console.error('swapExactTokensForTokens_E2B_error:', error);
  }
};

//Saeed code
// export const getValueOutEvcToBusd = async (amountEvc) => {
//     if (amountEvc > 0) {
//         console.log("amountEvc", amountEvc);
//         if (window.ethereum) {
//             const web3 = new Web3(window.ethereum);
//             const contracts = new web3.eth.Contract(abiEVCRouter, ContractAddressTrendRouter);
//             console.log("getValueOutEvcToBusdcontracts", contracts);
//             const path = [
//                 ContractAddressTRNDToken,
//                 ContractAddressUSDC
//             ];
//             console.log("e2bpath", path)
//             // alert(path)
//             const _finalamountWei = web3.utils.toWei(amountEvc.toString(), 'ether');
//             console.log("getValueOutEvcToBusd_finalamountWei", _finalamountWei)
//             const response = await contracts.methods.getAmountsOut(_finalamountWei, path).call();
//             console.log("getValueOutEvcToBusdContractresponse:", response);
//             const res = response[1];
//             // let resultAmount = web3.utils.fromWei(res, 'ether')
//             let resultAmount = Number(res) / 10 ** 6;
//             console.log("resultAmountE2B", resultAmount)
//             let resultValue = parseFloat(resultAmount).toFixed(8)
//             console.log("resultValueE2B", resultValue)
//             return resultValue;
//             // return response;
//         } else {
//             console.log("enter valid amount")
//         }
//     } else {
//         return 0;
//     }
// };
//Shreyash Code
export const getValueOutEvcToBusd = async (amountEvc) => {
    if (amountEvc > 0) {
      console.log("amountEvc", amountEvc);
      try {
        const contracts = new ethers.Contract(ContractAddressTrendRouter, abiEVCRouter, ethersProvider);
        console.log("getValueOutEvcToBusd contracts", contracts);
        const path = [ContractAddressTRNDToken, ContractAddressUSDC];
        console.log("e2bpath", path);
        const _finalamountWei = ethers.utils.parseEther(amountEvc.toString());
        console.log("getValueOutEvcToBusd _finalamountWei", _finalamountWei);
        const response = await contracts.getAmountsOut(_finalamountWei, path);
        console.log("getValueOutEvcToBusd Contract response:", response);
        const res = response[1];
        let resultAmount = Number(res) / 10 ** 6;
        console.log("resultAmountE2B", resultAmount);
        let resultValue = parseFloat(resultAmount).toFixed(8);
        console.log("resultValueE2B", resultValue);
        return resultValue;
      } catch (error) {
        console.error("Error in getValueOutEvcToBusd:", error);
      }
    } else {
      return 0;
    }
  };
  
getValueOutEvcToBusd(100)

//Sayyed Code
// export const getValueOutBusdToEvc = async (amountBusd) => {
//     if (amountBusd > 0) {
//         console.log("amountBusd", amountBusd);
//         if (window.ethereum) {
//             const web3 = new Web3(window.ethereum);

//             const contracts = new web3.eth.Contract(abiEVCRouter, ContractAddressTrendRouter);

//             console.log(contracts);
//             const path = [
//                 ContractAddressUSDC,
//                 ContractAddressTRNDToken
//             ];
//             console.log("amount", typeof amountBusd)
//             // const _finalamountWei = new web3.utils.BN(web3.utils.toWei(amountBusd.toString(), 'ether'));
//             const _finalamountWei = Number(amountBusd) * 10 ** 6;
//             console.log("_finalamountWei", _finalamountWei)
//             const response = await contracts.methods.getAmountsOut(_finalamountWei, path).call();
//             console.log("Contract response:", response);
//             const res = response[1];
//             let resultAmount = web3.utils.fromWei(res, 'ether')
//             console.log("resultAmountB2E", resultAmount)
//             let resultValue = parseFloat(resultAmount).toFixed(8)
//             console.log("resultAmountB2E", resultValue)
//             return resultValue;
//             // return response;
//         } else {
//             console.log("enter valid amount")
//         }
//     } else {
//         return 0;
//     }
// }
//Shreyash Code
export const getValueOutBusdToEvc = async (amountBusd) => {
    if (amountBusd > 0) {
      console.log("amountBusd", amountBusd);
      try {
        const contracts = new ethers.Contract(ContractAddressTrendRouter, abiEVCRouter, ethersProvider);
        console.log("getValueOutBusdToEvc contracts", contracts);
        const path = [ContractAddressUSDC, ContractAddressTRNDToken];
        console.log("amountBusd type:", typeof amountBusd);
        const _finalamountWei = ethers.BigNumber.from(Math.floor(Number(amountBusd) * 10 ** 6)); // Since BUSD uses 6 decimals
        console.log("_finalamountWei", _finalamountWei);
        const response = await contracts.getAmountsOut(_finalamountWei, path);
        console.log("getValueOutBusdToEvc Contract response:", response);
        const res = response[1];
        const resultAmount = ethers.utils.formatEther(res);
        console.log("resultAmountB2E", resultAmount);
        const resultValue = parseFloat(resultAmount).toFixed(8);
        console.log("resultAmountB2E", resultValue);
        return resultValue;
      } catch (error) {
        console.error("Error in getValueOutBusdToEvc:", error);
      }
    } else {
      return 0;
    }
  };
  
  //Saeed Code
// export const getAmountUserSwappedEvctoBusd = async () => {
//     try {
//         if (window.ethereum) {
//             const web3 = new Web3(window.ethereum);
//             // Get the selected account
//             const accounts = await window.ethereum.request({
//                 method: 'eth_accounts',
//             });
//             const account = accounts[0];
//             console.log("getAmountUserSwappedEvctoBusd_account", account);
//             // Create a new instance of the contract
//             const contract = new web3.eth.Contract(abiEVCRouter, ContractAddressTrendRouter);
//             const amountUserSwappedEvctoBusdInWei = await contract.methods.getUserSwapEvctoBusdAmount(account).call();
//             console.log("amountUserSwappedEvctoBusdInWei", amountUserSwappedEvctoBusdInWei);
//             // Convert the result to a human-readable format
//             const amountUserSwapEvctoBusd = web3.utils.fromWei(amountUserSwappedEvctoBusdInWei, "ether");
//             console.log("amountUserSwapEvctoBusd", amountUserSwapEvctoBusd);
//             // Round the amount to 6 decimal places
//             const amountuserSwapEvctoBusd = parseFloat(amountUserSwapEvctoBusd).toFixed(6);
//             console.log("getAmountUserSwappedEvctoBusd_return", amountuserSwapEvctoBusd);
//             // return amountuserSwapEvctoBusd;
//             return 2;

//         }
//     } catch (error) {
//         console.error('getAmountUserSwappedEvctoBusd_error:', error);
//     }
// };
//Shreyash Code
export const getAmountUserSwappedEvctoBusd = async () => {
    try {
      const account = await getCurrentAccount(); // Assuming you have a function to get the current account
      console.log("getAmountUserSwappedEvctoBusd_account", account);
        const contract = new ethers.Contract(ContractAddressTrendRouter, abiEVCRouter, ethersProvider);
     const amountUserSwappedEvctoBusdInWei = await contract.getUserSwapEvctoBusdAmount(account);
      console.log("amountUserSwappedEvctoBusdInWei", amountUserSwappedEvctoBusdInWei);
     const amountUserSwapEvctoBusd = ethers.utils.formatEther(amountUserSwappedEvctoBusdInWei);
      console.log("amountUserSwapEvctoBusd", amountUserSwapEvctoBusd);
      const amountUserSwapEvctoBusdRounded = parseFloat(amountUserSwapEvctoBusd).toFixed(6);
      console.log("getAmountUserSwappedEvctoBusd_return", amountUserSwapEvctoBusdRounded);
      return amountUserSwapEvctoBusdRounded;
    } catch (error) {
      console.error('getAmountUserSwappedEvctoBusd_error:', error);
      return null;
    }
  };
  
  //Sayyed Code
// export const getUserTotalledEVCMinted = async () => {
//     try {
//         if (window.ethereum) {
//             const web3 = new Web3(window.ethereum);
//             // Get the selected account
//             const accounts = await window.ethereum.request({
//                 method: 'eth_accounts',
//             });
//             const account = accounts[0];
//             console.log("getUserTotalledEVCMinted_account", account);
//             // Create a new instance of the contract
//             const contract = new web3.eth.Contract(abiEVCRouter, ContractAddressTrendRouter);
//             const userTotalEVCMintedInWei = await contract.methods.getUserTotalEVCMinted(account).call();
//             console.log("userTotalEVCMintedInWei", userTotalEVCMintedInWei);
//             // Convert the result to a human-readable format
//             const userTotalEVCMinted = web3.utils.fromWei(userTotalEVCMintedInWei, "ether");
//             console.log("userTotalEVCMinted", userTotalEVCMinted);
//             // Round the amount to 6 decimal places
//             const userTotalledEVCMinted = parseFloat(userTotalEVCMinted).toFixed(6);
//             console.log("getUserTotalledEVCMinted_return", userTotalledEVCMinted);
//             return userTotalledEVCMinted;
//             // return 3;
//         }
//     } catch (error) {
//         console.error('getUserTotalledEVCMinted_error:', error);
//     }
// };
//Shreyash Code
export const getUserTotalledEVCMinted = async () => {
    try {
      const account = await getCurrentAccount(); // Assuming you have a function to get the current account
      console.log("getUserTotalledEVCMinted_account", account);
     const contract = new ethers.Contract(ContractAddressTrendRouter, abiEVCRouter, ethersProvider);
     const userTotalEVCMintedInWei = await contract.getUserTotalEVCMinted(account);
      console.log("userTotalEVCMintedInWei", userTotalEVCMintedInWei);
     const userTotalEVCMinted = ethers.utils.formatEther(userTotalEVCMintedInWei);
      console.log("userTotalEVCMinted", userTotalEVCMinted);
    const userTotalledEVCMinted = parseFloat(userTotalEVCMinted).toFixed(6);
      console.log("getUserTotalledEVCMinted_return", userTotalledEVCMinted);
      return userTotalledEVCMinted;
    } catch (error) {
      console.error('getUserTotalledEVCMinted_error:', error);
      return null;
    }
  };
  
  //Saeed Code
// export const estimateGasFeeForSwapBUSDToEVC = async (amountIn) => {
//     if (amountIn > 0) {
//         try {
//             if (window.ethereum) {
//                 const web3 = new Web3(window.ethereum);
//                 // Get the selected account
//                 const accounts = await window.ethereum.request({
//                     method: 'eth_accounts',
//                 });
//                 const account = accounts[0];
//                 // Create a new instance of the contract
//                 const contract = new web3.eth.Contract(abiEVCRouter, ContractAddressTrendRouter);
//                 // Convert the input values to BigNumber or use string (if required)
//                 const amountInWei = web3.utils.toWei(amountIn.toString(), 'ether');
//                 const amountOutMin = 1;
//                 const amountOutMinWei = web3.utils.toWei(amountOutMin.toString(), 'ether');
//                 const path = [
//                     ContractAddressUSDC,
//                     ContractAddressTRNDToken
//                 ];
//                 // Calculate the deadline as 10 minutes ahead of the current time
//                 const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
//                 const deadline = currentTimestamp + 600; // 600 seconds = 10 minutes
//                 // Estimate gas cost for the swapExactTokensForTokens2 function
//                 const gasEstimate = await contract.methods
//                     .swapExactTokensForTokens(amountInWei, amountOutMinWei, path, account, deadline)
//                     .estimateGas({ from: account });
//                 // Get the current gas price
//                 const gasPrice = await web3.eth.getGasPrice();
//                 // Calculate the gas fee in Ether
//                 const gasFee = web3.utils.fromWei((gasEstimate * gasPrice).toString(), 'ether');
//                 console.log("estimateGasFeeForSwapBUSDToEVC", gasFee)
//                 return gasFee;
//             }
//         } catch (error) {
//             console.error('estimateGasFeeForSwapBUSDToEVC_error:', error);
//         }
//     }
//     return;
// };
//Shreyash Code
export const estimateGasFeeForSwapBUSDToEVC = async (amountIn) => {
    if (amountIn > 0) {
      try {
        const account = await getCurrentAccount(); // Assuming you have a function to get the current account
        console.log("estimateGasFeeForSwapBUSDToEVC_account", account);
          const contract = new ethers.Contract(ContractAddressTrendRouter, abiEVCRouter, ethersProvider);
          const amountInWei = ethers.utils.parseEther(amountIn.toString()); // Convert to Wei
        const amountOutMin = ethers.utils.parseEther("1"); // Minimum output amount
        const path = [ContractAddressUSDC, ContractAddressTRNDToken];
          const currentTimestamp = Math.floor(Date.now() / 1000);
        const deadline = currentTimestamp + 600;
          const gasEstimate = await contract.estimateGas.swapExactTokensForTokens(
          amountInWei,
          amountOutMin,
          path,
          account,
          deadline,
        );
          const gasPrice = await ethersProvider.getGasPrice();
          const gasFee = ethers.utils.formatEther(gasEstimate.mul(gasPrice));
        console.log("estimateGasFeeForSwapBUSDToEVC", gasFee);
        return gasFee;
      } catch (error) {
        console.error('estimateGasFeeForSwapBUSDToEVC_error:', error);
      }
    }
    return;
  };
  
// export const estimateGasFeeForSwap = async (amountIn) => {
//     if (amountIn > 0) {
//         try {
//             if (window.ethereum) {
//                 const web3 = new Web3(window.ethereum);
//                 // Get the selected account
//                 const accounts = await window.ethereum.request({
//                     method: 'eth_accounts',
//                 });
//                 const account = accounts[0];
//                 // Create a new instance of the contract
//                 const contract = new web3.eth.Contract(abiEVCRouter, ContractAddressTrendRouter);
//                 // Convert the input values to BigNumber or use string (if required)
//                 const amountInWei = web3.utils.toWei(amountIn.toString(), 'ether');
//                 const amountOutMin = 0;
//                 const amountOutMinWei = web3.utils.toWei(amountOutMin.toString(), 'ether');
//                 const path = [
//                     ContractAddressTRNDToken,
//                     ContractAddressUSDC
//                 ];
//                 // Calculate the deadline as 10 minutes ahead of the current time
//                 const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
//                 const deadline = currentTimestamp + 600; // 600 seconds = 10 minutes
//                 // Estimate gas cost for the swapTokensForStable function
//                 const gasEstimate = await contract.methods
//                     .swapExactTokensForTokens(amountInWei, amountOutMinWei, path, account, deadline)
//                     .estimateGas({ from: account });
//                 // Get the current gas price
//                 const gasPrice = await web3.eth.getGasPrice();
//                 // Calculate the gas fee in Ether
//                 const gasFee = web3.utils.fromWei((gasEstimate * gasPrice).toString(), 'ether');
//                 console.log("estimateGasFeeForSwap", gasFee)
//                 return gasFee;
//             }
//         } catch (error) {
//             console.error('estimateGasFeeForSwap_error:', error);
//         }
//     }
//     return;
// };

export const estimateGasFeeForSwap = async (amountIn) => {
    if (amountIn > 0) {
        try {
            // Get the current account from ethersProvider
            const account = await getCurrentAccount(); 
            console.log("estimateGasFeeForSwap_account", account);
            const contract = new ethers.Contract(ContractAddressTrendRouter, abiEVCRouter, ethersProvider);
            const amountInWei = ethers.utils.parseEther(amountIn.toString()); 
            const amountOutMinWei = ethers.utils.parseEther("0"); 
            const path = [ContractAddressTRNDToken, ContractAddressUSDC];
            const currentTimestamp = Math.floor(Date.now() / 1000);
            const deadline = currentTimestamp + 600;
            const gasEstimate = await contract.estimateGas.swapExactTokensForTokens(
                amountInWei,
                amountOutMinWei,
                path,
                account,
                deadline
            );
            const gasPrice = await ethersProvider.getGasPrice();
            const gasFee = ethers.utils.formatEther(gasEstimate.mul(gasPrice));
            console.log("estimateGasFeeForSwap", gasFee);
            return gasFee;
        } catch (error) {
            console.error('estimateGasFeeForSwap_error:', error);
        }
    }
    return;
};


// NOTE: EVC deployed for providing liquidity remove it when addressess are being changed
// and also replace variable in allownceEVC function and also remove ABIEVCToken in EVCStake.js in ABI file

// export const getValueOutBusdToEvc = async (amountBusd) => {
//     try {
//         if (window.ethereum) {
//             const web3 = new Web3(window.ethereum);
//             const accounts = await window.ethereum.request({
//                 method: 'eth_accounts',
//             });
//             const account = accounts[0];
//             const contracts = new web3.eth.Contract(abiEVCRouter, ContractAddressTrendRouter);
//             // Convert amountBusd to a BigNumber
//             const amountInBusd = new web3.utils.BN(web3.utils.toWei(amountBusd.toString(), 'ether'));
//             // Check if amountInEvc is zero
//             if (amountInBusd.isZero()) {
//                 return "0"; // Return "0" as a string
//             }
//             // Call the smart contract function
//             const response = await contracts.methods.getAmountoutBusdToEvc(amountInBusd).call();
//             const amountOutBUSD = web3.utils.fromWei(response, 'ether');
//             console.log('AmountoutBusdToEvc:', amountOutBUSD);
//             return amountOutBUSD;
//         }
//     } catch (error) {
//         console.error('getValueOutBusdToEvc', error);
//     }
// };
// getValueOutBusdToEvc(1)

// export const getValueOutEvcToBusd = async (amountEvc) => {
//     try {
//         if (window.ethereum) {
//             const web3 = new Web3(window.ethereum);
//             const accounts = await window.ethereum.request({
//                 method: 'eth_accounts',
//             });
//             const account = accounts[0];
//             const contracts = new web3.eth.Contract(abiEVCRouter, ContractAddressTrendRouter);
//             // Convert amountEvc to a BigNumber
//             const amountInEvc = new web3.utils.BN(web3.utils.toWei(amountEvc.toString(), 'ether'));
//             // Check if amountInEvc is zero
//             if (amountInEvc.isZero()) {
//                 return "0"; // Return "0" as a string
//             }
//             // Call the smart contract function
//             const response = await contracts.methods.getAmountoutEvcToBusd(amountInEvc).call();
//             const amountOutBUSD = web3.utils.fromWei(response, 'ether');
//             console.log('AmountoutEvcToBusd:', amountOutBUSD);
//             return amountOutBUSD;
//         }
//     } catch (error) {
//         console.error('getAmountoutEvcToBusd', error);
//     }
// };
// getValueOutEvcToBusd(1)
