import Web3 from "web3";
import { abiPool } from "./ABI/Pool";
import { RPCURL, CurrentChainID, ContractAddressTRNDVEST, getCurrentAccount, getCurrentSigner } from "./ContractDependency.js"
import { ethers } from 'ethers';
import { ethersProvider } from "../config/context.js";

var web3 = new Web3(new Web3.providers.HttpProvider(RPCURL));

//Saaed  Code
// export const getRemainingRbEvcValue = async () => {
//     console.log("getRemainingRbEvcValue");
//     let contracts = new web3.eth.Contract(abiPool, ContractAddressTRNDVEST);
//     const accounts = await window.ethereum.request({ method: 'eth_accounts' });
//     const account = accounts[0];
//     console.log("getRemainingRbEvcValue_account", account);
//     const response = await contracts.methods.getRemainingTTAmountRB(account).call();
//     console.log("getRemainingRbEvcValue", response);
//     const rbEvcValueRemaining = web3.utils.fromWei(response, 'ether');
//     console.log("rbEvcValueRemaining", rbEvcValueRemaining);
//     return rbEvcValueRemaining;
// };

//Shreyash Code
export const getRemainingRbEvcValue = async () => {
    console.log("getRemainingRbEvcValue");
    if (!ethersProvider) {
        console.error("ethersProvider is not available");
        return null;
    }
    try {
        const contracts = new ethers.Contract(ContractAddressTRNDVEST, abiPool, ethersProvider);
        const account = await getCurrentAccount();
        console.log("getRemainingRbEvcValue_account", account);
        const response = await contracts.getRemainingTTAmountRB(account);
        console.log("getRemainingRbEvcValue", response);
        const rbEvcValueRemaining = parseFloat(ethers.utils.formatEther(response));
        console.log("rbEvcValueRemaining", rbEvcValueRemaining);
        return rbEvcValueRemaining;
    } catch (error) {
        console.error("getRemainingRbEvcValueERR", error);
        return null; // Handle error as needed
    }
};

getRemainingRbEvcValue()

//Sayyedd Code
// export const getClaimedRbEvcValue = async () => {
//     console.log("getClaimedRbEvcValue");
//     let contracts = new web3.eth.Contract(abiPool, ContractAddressTRNDVEST);
//     const accounts = await window.ethereum.request({ method: 'eth_accounts' });
//     const account = accounts[0];
//     console.log("getClaimedRbEvcValue_account", account);
//     const response = await contracts.methods.getUserTTRedeemedAmount(account).call();
//     console.log("getClaimedRbEvcValue", response);
//     const rbEvcValueClaimed = web3.utils.fromWei(response, 'ether');
//     console.log("rbEvcValueClaimed", rbEvcValueClaimed);
//     return rbEvcValueClaimed;
// };

//Shreyash Code
export const getClaimedRbEvcValue = async () => {
    console.log("getClaimedRbEvcValue");
    if (!ethersProvider) {
        console.error("ethersProvider is not available");
        return null;
    }
    try {
        const contracts = new ethers.Contract(ContractAddressTRNDVEST, abiPool, ethersProvider);
        const account = await getCurrentAccount();
        console.log("getClaimedRbEvcValue_account", account);
        const response = await contracts.getUserTTRedeemedAmount(account);
        console.log("getClaimedRbEvcValue", response);
        const rbEvcValueClaimed = parseFloat(ethers.utils.formatEther(response));
        console.log("rbEvcValueClaimed", rbEvcValueClaimed);
        return rbEvcValueClaimed;
    } catch (error) {
        console.error("getClaimedRbEvcValueERR", error);
        return null; // Handle error as needed
    }
};

//Sayyeed Code
// export const getRbClaimPerc = async () => {
//     console.log("getRbClaimPerc");
//     let contracts = new web3.eth.Contract(abiPool, ContractAddressTRNDVEST);
//     const accounts = await window.ethereum.request({ method: 'eth_accounts' });
//     const account = accounts[0];
//     console.log("getRbClaimPerc_account", account);
//     const RbClaimPerc = await contracts.methods.getRewardPercentageTTRB(account).call();
//     console.log("RbClaimPerc", RbClaimPerc);
//     return RbClaimPerc;
// };
//Shreyash Code`
export const getRbClaimPerc = async () => {
    console.log("getRbClaimPerc");
    if (!ethersProvider) {
        console.error("ethersProvider is not available");
        return null;
    }
    try {
        const contracts = new ethers.Contract(ContractAddressTRNDVEST, abiPool, ethersProvider);
        const account = await getCurrentAccount();
        console.log("getRbClaimPerc_account", account);
        const rbClaimPerc = await contracts.getRewardPercentageTTRB(account);
        console.log("RbClaimPerc", rbClaimPerc);
        return rbClaimPerc.toString(); // Convert to string if needed
    } catch (error) {
        console.error("getRbClaimPercERR", error);
        return null; // Handle error as needed
    }
};

//Sayyed code 
// export const setClaimRB = async () => {
//     if (window.ethereum) {
//         const web3 = new Web3(window.ethereum);
//         await window.ethereum.request({ method: "eth_requestAccounts" });
//         const accounts = await window.ethereum.request({ method: "eth_accounts" });
//         const account = accounts[0];
//         const currentChainId = await web3.eth.net.getId();
//         if (currentChainId !== CurrentChainID) {
//             await web3.currentProvider.request({
//                 method: "wallet_switchEthereumChain",
//                 params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//             });
//         }
//         const contracts = new web3.eth.Contract(abiPool, ContractAddressTRNDVEST);
//         console.log(contracts);
//         const estimatedGasLimit = await contracts.methods
//             .claimVestRB()
//             .estimateGas({ from: account });
//         const gasPrice = await web3.eth.getGasPrice();
//         const claimvestRb = await contracts.methods.claimVestRB().send({
//             from: account,
//             gas: estimatedGasLimit,
//             gasPrice: gasPrice
//         });
//         console.log("claimvestRb", claimvestRb);
//         console.log('claimvestRb_transactionHash:', claimvestRb.transactionHash);
//         return claimvestRb.transactionHash;;
//     }
// };

//Shreyash Code
export const setClaimRB = async () => {
    try {
        const signer = await getCurrentSigner();
        const contract = new ethers.Contract(ContractAddressTRNDVEST, abiPool, signer);
        const estimatedGasLimit = await contract.estimateGas.claimVestRB();
        const gasPrice = await ethersProvider.getGasPrice();
        const transactionResponse = await contract.claimVestRB({
            gasLimit: estimatedGasLimit,
            gasPrice: gasPrice
        });
        const claimRBReceipt = await transactionResponse.wait();
        console.log("claimvestRb", claimRBReceipt);
        console.log('claimvestRb_transactionHash:', claimRBReceipt.transactionHash);
        return claimRBReceipt;
    } catch (error) {
        console.error('setClaimRB_error:', error);
    }
};